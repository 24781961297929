import { Component, useRef } from 'react';
import adminLayout from '../hoc/adminLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPlus, faPencil, faTrash, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import ModalComponent from '../common/ModalComponent';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import ENV from '../../config.json';

const UsersList = () => {
    const [inputFirstName, setInputFirstName] = useState('');
    const [inputLastName, setInputLastName] = useState('');
    const [inputEmail, setInputEmail] = useState('');
    const [inputRole, setInputRole] = useState('');
    const [inputPassword, setInputPassword] = useState('');
    const [inputId, setInputId] = useState('');
    const [inputMandal, setInputMandal] = useState('');
    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [previousUrl, setPreviousUrl] = useState('');
    const [nextUrl, setNextUrl] = useState('');
    const [post, setPost] = useState({});

    const { userslist } = useParams();
    const [data, setData] = useState([]);
    const [mandals, setMandals] = useState([]);
    const [userDataEdit, setUserDataEdit] = useState([]);
    const URL = ENV.NODE_API_URL + 'api/get-users-list'+window.location.search;
    
    const getMandalsCall = async () => {
        const mandalUrl = ENV.NODE_API_URL + 'api/mandals/list';
        const fullData = await axios.get(mandalUrl);
        const mandalsData = [];
        fullData.data.data.map(function(state) {
            let stateString = state.state.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase();
            if (state.districts) {
                state.districts.map(function(district) {
                    let districtString = district.district;
                    if (district.data) {
                        district.data.map(function(mandal){
                            mandalsData.push({key: mandal.key, label:  stateString + " - " + districtString + " - " + mandal.name});
                        });
                    }
                });
            }
        });
        setMandals(mandalsData);
    };

    const getCall = async () => {
        const { data } = await axios.get(URL);
        setData(data.data);
        const queryParams = new URLSearchParams(window.location.search);
        const currentUrl = window.location.origin + window.location.pathname;
        setPageNo(queryParams.get("page"));
        setPageSize(queryParams.get("size"));
        setPreviousUrl(currentUrl+'?page='+(queryParams.get("page") > 0 ? parseInt(queryParams.get("page")) - 1 : 0)+'&size='+queryParams.get("size") ?? 10);
        setNextUrl(currentUrl+'?page='+(queryParams.get("page") ? parseInt(queryParams.get("page")) + 1 : 1)+'&size='+queryParams.get("size") ?? 10)
    };

    const handleClick = async (user = null) => {
        if (user == null) {
            setUserDataEdit(current => {
                const {...rest} = {};
                return rest;
            });
        } else {
            setUserDataEdit(user);
        }
    };

    const resetUserDataEdit = async() => {
        setInputFirstName('')
        setInputLastName('')
        setInputEmail('')
        setInputRole()
        setInputPassword('')
        setInputId('')
        setInputMandal('')        
    };

    const handleDeleteUser = async (userId) => {
        const deleteUrl = ENV.NODE_API_URL + 'api/delete-user/'+userId;
        await axios.delete(deleteUrl).then((response) => {
            if (response.data.status == 'success') {
                window.location.reload();
            }
        }).catch((e) => {
                alert(e.response.data.error);
            });
    };

    const submitFormFunction = async () => {
        const reqBody = {
            _id : inputId,
            firstName: inputFirstName,
            lastName: inputLastName,
            email: inputEmail,
            hash_password: inputPassword,
            role: inputRole,
            mandal: inputMandal
        };
        console.log(reqBody);
        if(inputId !="") {
            const reqURl = ENV.NODE_API_URL + 'api/update-user';
            await axios.post(reqURl, reqBody).then((response) => {
                setPost(response.data);
                if (response.data.status == 'success') {
                    alert("User updated");
                    window.location.reload();
                }
            }).catch((e) => {
                alert(e.response.data.error);
            });
        } else {
            delete reqBody._id;
            const reqURl = ENV.NODE_API_URL + 'api/create-user';
            await axios.post(reqURl, reqBody).then((response) => {
                setPost(response.data);
                if (response.data.status == 'success') {
                    alert("User created");
                    window.location.reload();
                }
            }).catch((e) => {
                alert(e.response.data.error);
            });
        }
    };

    useEffect(() => {
        getCall()
    }, []);

    useEffect(() => {
        getMandalsCall()
    }, [data]);

    useEffect(() => {
        setInputFirstName(userDataEdit.firstName)
        setInputLastName(userDataEdit.lastName)
        setInputEmail(userDataEdit.email)
        setInputRole(userDataEdit.role)
        setInputPassword('')
        setInputId(userDataEdit._id)
        setInputMandal(userDataEdit.mandal)
    },[userDataEdit]);

    function modalFooterContent() {
        return (<div style={{width:"100%"}}>
            <button onClick={submitFormFunction} className="btn btn-default">Save</button> 
        </div>);
    }

    function modalContent() {
        return (
            <form id='user-submit-form'>
                <div className='container'>
                    <input type="hidden" id="record_id" name="record_id" value={inputId} onChange={val => setInputId(val.target.value)} />
                    <div className="mb-3">
                        <label htmlFor="firstName" className="form-label">First Name</label>
                        <input type="text" name='firstName' className="form-control" id="firstName" value={inputFirstName} onChange={val => setInputFirstName(val.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="lastName" className="form-label">Last Name</label>
                        <input type="text" name='lastName' className="form-control" id="lastName" value={inputLastName} onChange={val => setInputLastName(val.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email address</label>
                        <input type="email" name='email' className="form-control" id="email" aria-describedby="emailHelp" value={inputEmail} onChange={val => setInputEmail(val.target.value)} />
                        <div id="emailHelp" className="form-text">Email for the reference.</div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password</label>
                        <input type="password" className="form-control" id="password" value={inputPassword} onChange={val => setInputPassword(val.target.value)}/>
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='role' className='form-label'>Role</label>
                        <select className='form-control' id='role' name='role' value={inputRole} onChange={val => setInputRole(val.target.value)}>
                            <option selected>Select Role</option>
                            <option value='admin'>Admin</option>
                            <option value='user'>User</option>
                        </select>
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='mandal' className='form-label'>Mandal</label>
                        <select className='form-control' id='mandal' name='mandal' value={inputMandal} onChange={val => setInputMandal(val.target.value)}>
                            <option selected>Select Mandal</option>
                            {mandals.map((item) => (
                                <option value={item.key}>{item.label}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </form>
        );
    }

    return (
        <div className="table-container">
            <div className="row">
                <div className="col">
                    <h5 className="pb-2 mb-0">Users <FontAwesomeIcon icon={faUser} /></h5>
                </div>
                <div className="col text-right">
                    <button onClick={() => resetUserDataEdit()} className="btn btn-default low-height-btn">
                    <FontAwesomeIcon icon={faPlus} data-bs-toggle="modal" data-bs-target="#fullScreenModalDefault"/>
                    </button>
                </div>
            </div>
            <p>
            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
            </p>
            <div className="d-flex text-muted">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Created On</th>
                            <th>Updated On</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((user) => (
                            <tr>
                                <td>{user.firstName + " " + user.lastName}</td>
                                <td>{user.email}</td>
                                <td>{user.role}</td>
                                <td>{user.createdAt}</td>
                                <td>{user.updatedAt}</td>
                                <td>
                                    <div className="dropdown table-action-dropdown">
                                        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButtonSM" data-bs-toggle="dropdown" aria-expanded="false"><FontAwesomeIcon icon={faEllipsisV} /></button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtonSM">
                                            <li><a className="dropdown-item" href="#" onClick = {() => handleClick(user)} data-bs-toggle="modal" data-bs-target="#fullScreenModalDefault"><FontAwesomeIcon icon={faPencil} />&nbsp;Edit</a></li>
                                            <div className="dropdown-divider"></div>
                                            <li><a className="dropdown-item text-danger" onClick={() => handleDeleteUser(user._id)} href="#"><FontAwesomeIcon icon={faTrash} />&nbsp;Delete</a></li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <nav className="table-bottom-center-pagination" aria-label="Page navigation example ">
                <ul className="pagination">
                    {pageNo != 0 && pageNo != null ? (<li className="page-item">
                        <a className="page-link" href={previousUrl} aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                            <span className="sr-only">Previous</span>
                        </a>
                    </li>
                    ) : '' }
                    {data.length == pageSize ? (<li className="page-item">
                        <a className="page-link" href={nextUrl} aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                            <span className="sr-only">Next</span>
                        </a>
                    </li>) : ''}
                </ul>
            </nav>
            <ModalComponent title="Full Screen Modal" footerContent={modalFooterContent()} content={modalContent()} fullScreen="true" dataBsBackdrop="static" id="fullScreenModalDefault"/>
        </div>        
    )
}
export default adminLayout(UsersList);