// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  
  .footer {
    background-color: black;
    color: #fefefe;
    position: relative;
    width: 100%;
    bottom: 0;
    left: 0;
  }
  
  .footer .heading {
    color: #fefefe;
    max-width: 1010px;
    width: 90%;
    text-transform: uppercase;
    margin: 0 auto;
    margin-bottom: 3rem;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }
  
  .footer .content {
    display: flex;
    justify-content: space-evenly;
    margin: 1.5rem;
  }
  
  .footer .content p {
    margin-bottom: 1.3rem;
  }
  
  .footer .content a {
    text-decoration: none;
    color: #fefefe;
  }
  
  .footer .content a:hover {
    border-bottom: 1px solid #971717;
  }
  
  .footer .content h4 {
    margin-bottom: 1.3rem;
    font-size: 19px;
  }
  
  footer hr {
    margin: 0.1rem 0;
  }
  
  @media (max-width: 767px) {
    .footer .content {
      display: flex;
      flex-direction: column;
      font-size: 14px;
    }
  
    .footer {
      position: unset;
    }
  }
  
  @media (min-width: 768px) and (max-width: 1024px) {
    .footer .content,
    .footer {
      font-size: 14px;
    }
  }
  
  @media (orientation: landscape) and (max-height: 500px) {
    .footer {
      position: unset;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/common/footer.css"],"names":[],"mappings":";;EAEE;IACE,uBAAuB;IACvB,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,OAAO;EACT;;EAEA;IACE,cAAc;IACd,iBAAiB;IACjB,UAAU;IACV,yBAAyB;IACzB,cAAc;IACd,mBAAmB;IACnB,6EAA6E;EAC/E;;EAEA;IACE,aAAa;IACb,6BAA6B;IAC7B,cAAc;EAChB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,qBAAqB;IACrB,cAAc;EAChB;;EAEA;IACE,gCAAgC;EAClC;;EAEA;IACE,qBAAqB;IACrB,eAAe;EACjB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE;MACE,aAAa;MACb,sBAAsB;MACtB,eAAe;IACjB;;IAEA;MACE,eAAe;IACjB;EACF;;EAEA;IACE;;MAEE,eAAe;IACjB;EACF;;EAEA;IACE;MACE,eAAe;IACjB;EACF","sourcesContent":["\n  \n  .footer {\n    background-color: black;\n    color: #fefefe;\n    position: relative;\n    width: 100%;\n    bottom: 0;\n    left: 0;\n  }\n  \n  .footer .heading {\n    color: #fefefe;\n    max-width: 1010px;\n    width: 90%;\n    text-transform: uppercase;\n    margin: 0 auto;\n    margin-bottom: 3rem;\n    font-family: \"Gill Sans\", \"Gill Sans MT\", Calibri, \"Trebuchet MS\", sans-serif;\n  }\n  \n  .footer .content {\n    display: flex;\n    justify-content: space-evenly;\n    margin: 1.5rem;\n  }\n  \n  .footer .content p {\n    margin-bottom: 1.3rem;\n  }\n  \n  .footer .content a {\n    text-decoration: none;\n    color: #fefefe;\n  }\n  \n  .footer .content a:hover {\n    border-bottom: 1px solid #971717;\n  }\n  \n  .footer .content h4 {\n    margin-bottom: 1.3rem;\n    font-size: 19px;\n  }\n  \n  footer hr {\n    margin: 0.1rem 0;\n  }\n  \n  @media (max-width: 767px) {\n    .footer .content {\n      display: flex;\n      flex-direction: column;\n      font-size: 14px;\n    }\n  \n    .footer {\n      position: unset;\n    }\n  }\n  \n  @media (min-width: 768px) and (max-width: 1024px) {\n    .footer .content,\n    .footer {\n      font-size: 14px;\n    }\n  }\n  \n  @media (orientation: landscape) and (max-height: 500px) {\n    .footer {\n      position: unset;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
