import { Component, useRef } from 'react';
import adminLayout from '../hoc/adminLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPlus, faPencil, faTrash, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import ModalComponent from '../common/ModalComponent';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import ENV from '../../config.json';
import FileBase64 from 'react-file-base64';

const MandalsList = () => {
    const [inputName, setInputName] = useState('');
    const [inputDescription, setInputDescription] = useState('');
    const [inputKey, setInputKey] = useState('');
    const [inputUpdatedBy, setInputUpdatedBy] = useState('');
    const [inputId, setInputId] = useState('');
    const [inputAdds, setInputAdds] = useState([]);
    const [inputImage, setInputImage] = useState('');
    const [inputState, setInputState] = useState('');
    const [inputDistrict, setInputDistrict] = useState('');
    // const [inputDistrictLabel, setInputDistrictLabel] = useState('');
    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [previousUrl, setPreviousUrl] = useState('');
    const [nextUrl, setNextUrl] = useState('');
    const [districts, setDistricts] = useState([]);

    const [data, setData] = useState([]);
    const [userDataEdit, setUserDataEdit] = useState([]);

    const getCall = async () => {
        const URL = ENV.NODE_API_URL + 'api/mandals/list/all'+window.location.search;
        const { data } = await axios.get(URL);
        const outputData = [];
        setData(data.data);
        const queryParams = new URLSearchParams(window.location.search);
        const currentUrl = window.location.origin + window.location.pathname;
        setPageNo(queryParams.get("page"));
        setPageSize(queryParams.get("size"));
        setPreviousUrl(currentUrl+'?page='+(queryParams.get("page") > 0 ? parseInt(queryParams.get("page")) - 1 : 0)+'&size='+queryParams.get("size") ?? 10);
        setNextUrl(currentUrl+'?page='+(queryParams.get("page") ? parseInt(queryParams.get("page")) + 1 : 1)+'&size='+queryParams.get("size") ?? 10)
    };

    const handleClick = async (user = null) => {
        if (user == null) {
            setUserDataEdit(current => {
                const {...rest} = {};
                return rest;
            });
        } else {
            setUserDataEdit(user);
        }
    };

    const handleDeleteUser = async (userId) => {
        const deleteUrl = ENV.NODE_API_URL + 'api/mandals/delete/'+userId;
        await axios.delete(deleteUrl).then((response) => {
            if (response.data.status == 'success') {
                window.location.reload();
            }
        }).catch((e) => {
            alert(e.response.data.error);
        });
    };

    const submitFormFunction = async () => {
        const reqBody = {
            _id : inputId,
            name: inputName,
            description: inputDescription,
            key: inputKey,
            image: inputImage,
            updated_by: inputUpdatedBy,
            state: inputState,
            adds: inputAdds,
            district: inputDistrict
            // district_label: inputDistrictLabel
        };
        if(inputId !== undefined) {
            const reqURl = ENV.NODE_API_URL + 'api/mandals/update';
            await axios.post(reqURl, reqBody).then((response) => {
                if (response.data.status == 'success') {
                    alert("Mandal updated");
                    window.location.reload();
                }
            }).catch((e) => {
                alert(e.response.data.error);
            });        
        } else {
            const reqURl = ENV.NODE_API_URL + 'api/mandals/create';
            await axios.post(reqURl, reqBody).then((response) => {
                if (response.data.status == 'success') {
                    alert("Mandal created");
                    window.location.reload();
                }
            }).catch((e) => {
                alert(e.response.data.error);
            });
        }
    };

    useEffect(() => {
        getCall()
    }, []);

    useEffect(() => {
        setInputName(userDataEdit.name)
        setInputDescription(userDataEdit.description)
        setInputKey(userDataEdit.key)
        setInputState(userDataEdit.state)
        setInputImage(userDataEdit.image)
        setInputUpdatedBy(userDataEdit.updated_by)
        setInputId(userDataEdit._id)
        setInputAdds(userDataEdit.adds)
        setInputDistrict(userDataEdit.district)
        // setInputDistrictLabel(userDataEdit.district_label)
    },[userDataEdit]);

    const getImageFile = (files) => {
        setInputImage(files.base64);
    }

    const getAddsFiles = (files) => {
        let images = [];
        for(let i= 0; i < files.length; i++) {
            images.push(files[i].base64);
        }
        setInputAdds(images);
    }

    const triggerStateChange = async (state) => {
        setInputState(state);
        const URL = ENV.NODE_API_URL + 'api/districts/all/'+state;
        const { data } = await axios.get(URL);
        setDistricts(data.data);
    }

    const triggerDistrictChange = async (district) => {
        setInputDistrict(district.target.value);
        // setInputDistrictLabel(district.target.options[district.target.options.selectedIndex].innerHTML);
    }
    

    function modalFooterContent() {
        return (<div style={{width:"100%"}}>
            <button onClick={submitFormFunction} className="btn btn-default">Save</button> 
        </div>);
    }

    function modalContent() {
        return (
            <form id='user-submit-form'>
                <div className='container'>
                    <input type="hidden" id="record_id" name="record_id" value={inputId} onChange={val => setInputId(val.target.value)} />
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input type="text" name='name' className="form-control" id="name" value={inputName} onChange={val => setInputName(val.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="key" className="form-label">Key</label>
                        <input type="text" name='key' className="form-control" id="key" value={inputKey} onChange={val => setInputKey(val.target.value)} />
                    </div>                    
                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">Description</label>
                        <textarea name='description' className="form-control" id="description" value={inputDescription} onChange={val => setInputDescription(val.target.value)}></textarea>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="image" className="form-label">Image</label>
                        <FileBase64 className="form-control" type="file" multiple={ false } onDone={ getImageFile.bind(this) } />
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='state' className='form-label'>State</label>
                        <select className='form-control' data-live-search="true" id='state' name='state' value={inputState} onChange={val => triggerStateChange(val.target.value)}>
                            <option selected>Select State</option>
                            {ENV.STATE_VALUES.split(",").map((item) => (
                                <option value={item}>{item.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase()}</option>
                            ))}
                        </select>
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='state' className='form-label'>District</label>
                        <select className='form-control' data-live-search="true" id='district' name='district' value={inputDistrict} onChange={val => triggerDistrictChange(val)}>
                            <option selected>Select District</option>
                            {districts.map((item) => (
                                <option value={item.key}>{item.name}</option>
                            ))}
                        </select>
                    </div>                    
                    <div className="mb-3">
                        <label htmlFor="adds" className="form-label">Adds</label>
                        <FileBase64 id="adds" className="form-control" type="file" multiple={ true } onDone={ getAddsFiles.bind(this) } />
                    </div>
                </div>
            </form>
        );
    }

    return (
        <div className="table-container">
            <div className="row">
                <div className="col">
                    <h5 className="pb-2 mb-0">Mandals List <FontAwesomeIcon icon={faUser} /></h5>
                </div>
                <div className="col text-right">
                    <button onClick={() => handleClick()} className="btn btn-default low-height-btn">
                    <FontAwesomeIcon icon={faPlus} data-bs-toggle="modal" data-bs-target="#fullScreenModalDefault"/>
                    </button>
                </div>
            </div>
            <p>
            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
            </p>
            <div className="d-flex text-muted">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Key</th>
                            <th>District</th>
                            <th>State</th>
                            <th>Updated By</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((user) => (
                            <tr>
                                <td><img src={user.image} width="50px" height="50px" /></td>
                                <td>{user.name}</td>
                                <td>{user.key}</td>
                                <td>{user.district}</td>
                                <td>{user.state}</td>
                                <td>{user.updated_by}</td>
                                <td>
                                    <div className="dropdown table-action-dropdown">
                                        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButtonSM" data-bs-toggle="dropdown" aria-expanded="false"><FontAwesomeIcon icon={faEllipsisV} /></button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtonSM">
                                            <li><a className="dropdown-item" href="#" onClick = {() => handleClick(user)} data-bs-toggle="modal" data-bs-target="#fullScreenModalDefault"><FontAwesomeIcon icon={faPencil} />&nbsp;Edit</a></li>
                                            <div className="dropdown-divider"></div>
                                            <li><a className="dropdown-item text-danger" onClick={() => handleDeleteUser(user._id)} href="#"><FontAwesomeIcon icon={faTrash} />&nbsp;Delete</a></li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <nav className="table-bottom-center-pagination" aria-label="Page navigation example ">
                <ul className="pagination">
                    {pageNo != 0 && pageNo != null ? (<li className="page-item">
                        <a className="page-link" href={previousUrl} aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                            <span className="sr-only">Previous</span>
                        </a>
                    </li>
                    ) : '' }
                    {data.length == pageSize ? (<li className="page-item">
                        <a className="page-link" href={nextUrl} aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                            <span className="sr-only">Next</span>
                        </a>
                    </li>) : ''}
                </ul>
            </nav>
            <ModalComponent title="Full Screen Modal" footerContent={modalFooterContent()} content={modalContent()} fullScreen="true" dataBsBackdrop="static" id="fullScreenModalDefault"/>
        </div>        
    )
}
export default adminLayout(MandalsList);