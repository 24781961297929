import { Component } from 'react';
import adminLayout from '../hoc/adminLayout';

class Dashboard extends Component {
    render() {
        return (
            <div>Dashboard</div>
        )
    }
}
export default adminLayout(Dashboard);