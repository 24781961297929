import { Component } from 'react';
import logo from '../../telugu-logo.jpeg';
import "./footer.css";

class Footer extends Component {
    render() {
        return (
		<footer className="bg-third text-light">
			<div className="container py-5">
				<div className="row">
					<div className="col-md-3">
						<img src={logo} className="mb-3" width="200" alt="logo" />
						<p>Launching soon: Telugu News, your go-to media protal delivering daily updates on the two Telugu states, spanning 1280 mandals. Comprehensive coverage includes news on politics, celebrities, events, and more. Stay tuned for insightful updates.</p>
					</div>
					<div className="col-md-3">
						<h4>Quick Links</h4>
						<ul className="nav flex-column">
							<li className="nav-item">
								<a className="nav-link text-light" href="#">Home</a>
							</li>
							<li className="nav-item">
								<a className="nav-link text-light" href="/about-us">About</a>
							</li>
							<li className="nav-item">
								<a className="nav-link text-light" href="#">Andhra Pradesh</a>
							</li>
							<li className="nav-item">
								<a className="nav-link text-light" href="#">Telangana</a>
							</li>
							<li className="nav-item">
								<a className="nav-link text-light" href="#">Contact</a>
							</li>
						</ul>
					</div>
					<div className="col-md-3">
						<h4>Social Media</h4>
						<ul className="nav flex-column">
							<li className="nav-item">
								<a className="nav-link text-light" href="#"><i className="fa-brands fa-facebook-f"></i> &nbsp;Facebook</a>
							</li>
							<li className="nav-item">
								<a className="nav-link text-light" href="#"><i className="fa-brands fa-instagram"></i> &nbsp;Instagram</a>
							</li>
							<li className="nav-item">
								<a className="nav-link text-light" href="#"><i className="fa-brands fa-linkedin-in"></i> &nbsp;Linkedin</a>
							</li>
							<li className="nav-item">
								<a className="nav-link text-light" href="#"><i className="fa-brands fa-x-twitter"></i> &nbsp;Twitter</a>
							</li>
						</ul>
					</div>
					<div className="col-md-3">
						<h4>Contact Us</h4>
						<h6>Email:</h6>
						<ul className="nav flex-column">
							<li className="nav-item">
								<a className="nav-link text-light" href="#"><i className="fa-regular fa-envelope"></i> editor@telugu.news</a>
							</li>
						</ul>
						<h6>Phone:</h6>
						<ul className="nav flex-column">
							<li className="nav-item">
								<a className="nav-link text-light" href="#"><i className="fa-solid fa-phone"></i> +91 8688815688</a>
							</li>
						</ul>
					</div>
					<hr />
					<div className="col-md-12 text-center">
						<p className="mb-0">&copy; 2023, Telugu.news. All Rights Reserved.</p>
					</div>
				</div>
			</div>
		</footer>    
        )
    }
}
export default Footer;

