import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Nopage from './components/common/nopage';
import Home from "./components/website/Home";
import About from "./components/website/About";
import Newsview from "./components/website/Newsview";
import MandalHome from "./components/website/MandalHome";
import Dashboard from './components/admin/dashboard';
import LoginPage from './components/admin/LoginPage';
import UsersList from './components/admin/UsersList';
import CategoriesList from './components/admin/CategoriesList';
import NewsList from './components/admin/NewsList';
import MandalsList from './components/admin/MandalsList';
import Contact from './components/website/Contact';

function App() {
  return (
    <BrowserRouter>
      <div className='body-class w-100'>
          <Routes>
            <Route path='/'>
              <Route index exact path='/' element={<Home />} />
              <Route path=':mandal' element={<MandalHome />}>
                <Route path=':key' element={<MandalHome />} />
              </Route>
              <Route path='news-view/:id' element={<Newsview />} />
              <Route exact path='/about-us' element={<About />} />
              <Route exact path='/contact' element={<Contact />} />
            </Route>
            <Route path='/admin'>
              <Route index element={<LoginPage />} />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='users' element={<UsersList />} />
              <Route path='categories' element={<CategoriesList />} />
              <Route path='news' element={<NewsList />} />
              <Route path='mandals' element={<MandalsList /> } />
            </Route>
            <Route path='*' element={<Nopage />} />
          </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
