import React from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import ENV from '../../config.json';
import frontendLayout from '../hoc/frontendLayout';

const Newsview = () => {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const URL = ENV.NODE_API_URL + 'api/news/' + id;

    const getCall = async () => {
        const { data } = await axios.get(URL);
        setData(data.data);
    };

    useEffect(() => {
        getCall()
    }, []);

    return (
        <div>
            {data.news &&
                <div className='container py-3'>
                    <h1 className='text-center'>{data.news.title}</h1>
                    <div className='row'>
                        <div className='col-md-9 col-lg-9 col-sm-6'>
                            <div className='mandal-image mb-3 text-center'>
                                <img src={data.news.images[0]} className='w-100' />
                            </div>
                            <div className='mandal-description'>
                                <p>{data.news.description}</p>
                            </div>
                        </div>
                        <div className='col-md-3 col-lg-3 col-sm-6'>
                            {data.mandal.adds.map((add) => (
                                <div style={{padding: '10px'}}>
                                    <a href='#'><img alt="image name" className='w-100' src={add} /></a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
export default frontendLayout(Newsview);
