import { React, useEffect, useState } from "react";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTable, faStar, faNewspaper } from '@fortawesome/free-solid-svg-icons';
// import logo from '../../assets/images/telugunews.jpg';

const Sidebar = () => {
    const [customerData, setCustomerData] = useState({});
    const navigate = useNavigate();
    const checkLoginData = async () => {
        if (window.localStorage.getItem('customerData')) {
            setCustomerData(JSON.parse(window.localStorage.getItem('customerData')));
        } else {
            setTimeout(
                () => navigate('/admin', { replace: true })
            , 100);            
        }
    } 

    useEffect(() => {
        checkLoginData()
    }, []);

    return (
        <div className="border-end sidenav" id="sidebar-wrapper">
            <div className="sidebar-heading border-bottom ">
                <Link to="/admin/dashboard">
                    {/* <img alt="Alt content" src={logo} /> */}
                </Link>
            </div>
            <PerfectScrollbar className="sidebar-items">
                <ul className="list-unstyled ps-0">
                    <li className="mb-1">
                        <Link tag="a" className="" to="/admin/dashboard">
                        <FontAwesomeIcon icon={faTable} /> Dashboard
                        </Link>
                    </li>
                    <li className="mb-1">
                        <Link tag="a" className="" to="/admin/users">
                        <FontAwesomeIcon icon={faUser} /> Users List
                        </Link>
                    </li>
                    <li className="mb-1">
                        <Link tag="a" className="" to="/admin/categories">
                            <FontAwesomeIcon icon={faTable} /> Categories List
                        </Link>
                    </li>
                    <li className="mb-1">
                        <Link tag="a" className="" to="/admin/news">
                        <FontAwesomeIcon icon={faNewspaper} /> News List
                        </Link>
                    </li>
                    <li className="mb-1">
                        <Link tag="a" className="" to="/admin/mandals">
                        <FontAwesomeIcon icon={faNewspaper} /> Mandals List
                        </Link>
                    </li>
                    <li className="border-top my-3"></li>
                    
                    {/* collapsable list item example */}
                    {/* <li className="mb-1">
                        <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#dashboard-collapse" aria-expanded="false">
                        Opportunity
                        </button>
                        <div className="collapse" id="dashboard-collapse">
                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                            <li><a href="#" className="rounded">Overview</a></li>
                            <li><a href="#" className="rounded">Weekly</a></li>
                            <li><a href="#" className="rounded">Monthly</a></li>
                            <li><a href="#" className="rounded">Annually</a></li>
                        </ul>
                        </div>
                    </li> 
                    <li className="border-top my-3"></li> */}
                   
                </ul>
            </PerfectScrollbar>
            <div className="dropdown fixed-bottom-dropdown">
                <a href="#" className="d-flex align-items-center text-decoration-none dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="https://via.placeholder.com/50" alt="" width="32" height="32" className="rounded-circle me-2" />
                    <span>{customerData.firstName + ' ' + customerData.lastName}</span>
                </a>
                <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
                    <li><Link className="dropdown-item" to="/profile"><i className="fa fa-user-circle" aria-hidden="true"></i> Profile</Link></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><Link className="dropdown-item" onClick={() => window.localStorage.clear()} to="/admin"><i className="fa fa-sign-out" aria-hidden="true"></i> Sign out</Link></li>
                </ul>
            </div>
        </div>
    )
}

export default Sidebar;