import { React, useEffect, useState } from "react";
import "./login.css"
import { Link, useNavigate } from 'react-router-dom';
import authLayout from "../hoc/authLayout";
import axios from 'axios';
import ENV from '../../config.json';

const LoginPage = () => {

    const [inputEmail, setInputEmail] = useState('');
    const [inputPassword, setInputPassword] = useState('');
    const [isClicked, setIsClicked] = useState(false);

    const navigate = useNavigate();
    const loginApiCall = async (isClicked = false) => {
        if (isClicked) {
            const reqBody = {
                username: inputEmail,
                password: inputPassword
            }
            const URL = ENV.NODE_API_URL + 'api/login';
            await axios.post(URL, reqBody).then((response) => {
                if (response.status == 200) {
                    window.localStorage.setItem('customerData', JSON.stringify(response.data.data));
                    window.localStorage.setItem('jwtToken', response.data.jwt);
                    checkLoginData();
                }
            }).catch((e) => {
                alert(e.response.data.error);
            });
        }
    }

    const checkLoginData = () => {
        if (window.localStorage.getItem('customerData')) {
            setTimeout(
                () => navigate('/admin/dashboard', { replace: true })
            , 100);
        }
    }

    useEffect(() => {
        checkLoginData()
    }, []);

    return (
        <form className="login-form">
            <div className="d-flex align-items-center my-4">
                <h1 className="text-center fw-normal mb-0 me-3">Sign In</h1>
            </div>
            {/* <!-- Email input --> */}
            <div className="form-outline mb-4">
                <label className="form-label" htmlFor="form3Example3">Email address</label>
                <input type="email" id="form3Example3" className="form-control form-control-lg"
                placeholder="Enter a valid email address" value={inputEmail} onChange={val => setInputEmail(val.target.value)} />
            </div>

            {/* <!-- Password input --> */}
            <div className="form-outline mb-3">
                <label className="form-label" htmlFor="form3Example4">Password</label>
                <input type="password" id="form3Example4" className="form-control form-control-lg"
                placeholder="Enter password" value={inputPassword} onChange={val => setInputPassword(val.target.value)} />
            </div>

            <div className="text-center text-lg-start mt-4 pt-2">
                <button onClick={() => loginApiCall(true)} type="button" className="btn btn-primary btn-lg">Login</button>
            </div>
        </form>
    )
}

export default authLayout(LoginPage);