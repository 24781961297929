import React from 'react';
import frontendLayout from '../hoc/frontendLayout';


const Contact = () => {
  return (
    <>Contact</>
  )
}

export default frontendLayout(Contact);