// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer,
.header,
.home-body {
  /* width: 100%; */
  text-align: center;
  height: '70%';
}

.footer {
  border-top: 1px solid black;
  font-size: 25px;
  padding: 10px;
  text-align: left;
}

.header {
  border-bottom: 1px solid black;
}

.header .logo {
  width: 15%;
  height: 15%;
}

.mandal-card {
  padding: 10px;
}

.footer-list-left {
  padding-top: 30px;
}

@media only screen and (max-width: 786px) {
  .header .logo {
    width: 40%;
    height: 40%;
  }

  .footer .logo {
    width: 80%;
    height: 80%;
  }

  .footer {
    text-align: center !important;
  }
}

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;;;EAGE,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,2BAA2B;EAC3B,eAAe;EACf,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,UAAU;IACV,WAAW;EACb;;EAEA;IACE,UAAU;IACV,WAAW;EACb;;EAEA;IACE,6BAA6B;EAC/B;AACF","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.footer,\n.header,\n.home-body {\n  /* width: 100%; */\n  text-align: center;\n  height: '70%';\n}\n\n.footer {\n  border-top: 1px solid black;\n  font-size: 25px;\n  padding: 10px;\n  text-align: left;\n}\n\n.header {\n  border-bottom: 1px solid black;\n}\n\n.header .logo {\n  width: 15%;\n  height: 15%;\n}\n\n.mandal-card {\n  padding: 10px;\n}\n\n.footer-list-left {\n  padding-top: 30px;\n}\n\n@media only screen and (max-width: 786px) {\n  .header .logo {\n    width: 40%;\n    height: 40%;\n  }\n\n  .footer .logo {\n    width: 80%;\n    height: 80%;\n  }\n\n  .footer {\n    text-align: center !important;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
