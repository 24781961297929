import logo from '../../telugu-logo.jpeg';
import popup from '../../popup.jpeg';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import ENV from '../../config.json';
import { useParams } from 'react-router-dom';
import { NavLink as Link } from 'react-bootstrap';

const Header = () => {
    const [menu, setMenu] = useState([]);
    const { mandal } = useParams();
    const [urlFormat, setUrlFormat] = useState('/:mandal/:key');
    const [loadmodal, setloadmodal] = useState(true);

    const closeloadModal = () => {
        setloadmodal(false);
    }

    const menuApiCall = async () => {
        const menuUrl = ENV.NODE_API_URL + 'api/menu';
        const categories = await axios.get(menuUrl);
        setMenu(categories.data.data);
    }

    const onHoverMenuFunction = () => {
        document.addEventListener("DOMContentLoaded", function(){
            if (window.innerWidth > 992) {
                document.querySelectorAll('.navbar .nav-item').forEach(function(everyitem){            
                    everyitem.addEventListener('mouseover', function(e){
                        let el_link = this.querySelector('a[data-bs-toggle]');
                        if(el_link != null){
                            let nextEl = el_link.nextElementSibling;
                            el_link.classList.add('show');
                            nextEl.classList.add('show');
                        }
                    });
                    everyitem.addEventListener('mouseleave', function(e){
                        let el_link = this.querySelector('a[data-bs-toggle]');
                        if(el_link != null){
                            let nextEl = el_link.nextElementSibling;
                            el_link.classList.remove('show');
                            nextEl.classList.remove('show');
                        }
                    })
                });
            }
        });
    }

    useEffect(() => {
        menuApiCall()
        setUrlFormat('/:mandal/:key')
        onHoverMenuFunction()
    }, []);

    return (
        <>
        <div className="bg-third">
			<div className="container-fluid py-1">
				<ul className="nav d-flex justify-content-between">
					<li className="nav-item">
						<a className="nav-link text-light" href="#">Email: <i className="fa-regular fa-envelope"></i> editor@telugu.news</a>
					</li>
					<li className="nav-item">
						<a className="nav-link text-light" href="#">Call Now! <i className="fa-solid fa-phone"></i> +91 8688815688</a>
					</li>
				</ul>
			</div>
		</div>
        { window.location.pathname === '/' || window.location.pathname === '/about-us' ? (<nav className="navbar navbar-expand-lg bg-body-tertiary shadow">
			<div className="container-fluid">
				<a className="navbar-brand" href="/">
					<img src={logo} width="200" alt="logo" />
				</a>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item" id='home'>
							<Link className="nav-link" href="/">HOME</Link>
						</li>                        
						<li className="nav-item" id='about'>
							<Link className="nav-link" href="/about-us">ABOUT</Link>
						</li>
					</ul>
				</div>
			</div>
		</nav>) : (<nav className="navbar navbar-expand-lg bg-body-tertiary shadow">
			<div className="container-fluid">
				<Link className="navbar-brand" href="/">
					<img src={logo} width="200" alt="logo" />
				</Link>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav ms-auto mb-2 mb-lg-0">
						<li className="nav-item" id='home'>
							<Link className="nav-link" href="/">HOME</Link>
						</li>                        
						<li className="nav-item" id='about'>
							<Link className="nav-link" href="/about-us">ABOUT</Link>
						</li>
                        {menu.map((item) => ( item.values.length ? (
                            <li className="nav-item dropdown" id={item.type}>
                                <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">{item.type.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase()}</a>
                                <ul className="dropdown-menu">
                                    {item.values.map((childItem) => (
                                        <li id={childItem.key}><Link className="dropdown-item" href={urlFormat.replace(':mandal', mandal).replace(':key', childItem.key)}>{childItem.name}</Link></li>
                                    ))}
                    		    </ul>
                            </li>) : ''
                        ))}
					</ul>
				</div>
			</div>
		</nav>)
        }
        <marquee onMouseOver={(e) => e.target.stop()} onMouseOut={(e) => e.target.start()} style={{backgroundColor: 'red',color: 'white',fontWeight: '600', padding: '5px', fontSize: 'large'}}>Website will be fully operational in few days.</marquee>
        <div className={`modal modal bg-dark bg-opacity-75 ${loadmodal ? 'd-block' : 'd-none'}`} id="onload" tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body p-0 position-relative">
                        <button type="button" className="btn btn-danger rounded-pill position-absolute top-0 start-100" onClick={closeloadModal} style={{marginLeft: '-20px', marginTop: '-20px'}}><i className='fa-solid fa-xmark'></i></button>
                        <Link href='https://www.tm.university/' target='_blank'>
                            <img src={popup} className='w-100' alt='popup' />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}
export default Header;