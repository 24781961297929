import { Component } from 'react';
import Footer from './footer';
import Header from './header';

class Nopage extends Component {
    render() {
        return (
            <div>
                <div>NoPage</div>
            </div>
        )
    }
}
export default Nopage;