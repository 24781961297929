import { Component, useRef } from 'react';
import adminLayout from '../hoc/adminLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPlus, faPencil, faTrash, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import ModalComponent from '../common/ModalComponent';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import ENV from '../../config.json';
import FileBase64 from 'react-file-base64';

const NewsList = () => {
    const [inputTitle, setInputTitle] = useState('');
    const [inputDescription, setInputDescription] = useState('');
    const [inputCategories, setInputCategories] = useState([]);
    const [inputUpdatedBy, setInputUpdatedBy] = useState('');
    const [inputId, setInputId] = useState('');
    const [inputMandal, setInputMandal] = useState('');
    const [inputImages, setInputImages] = useState([]);
    const [inputYoutubeVideo, setInputYoutubeVideo] = useState('');
    const [inputMetaTitle, setInputMetaTitle] = useState('');
    const [inputMetaDescription, setInputMetaDescription] = useState('');
    const [inputMetaKeywords, setInputMetaKeywords] = useState('');
    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [previousUrl, setPreviousUrl] = useState('');
    const [nextUrl, setNextUrl] = useState('');
    const [post, setPost] = useState({});
    const [customerData, setCustomerData] = useState({});

    const [data, setData] = useState([]);
    const [mandals, setMandals] = useState([]);
    const [categories, setCategories] = useState([]);
    const [userDataEdit, setUserDataEdit] = useState([]);

    const checkLoginData = async () => {
        if (window.localStorage.getItem('customerData')) {
            setCustomerData(JSON.parse(window.localStorage.getItem('customerData')));
        }
    }

    const getMandalsCall = async () => {
        const mandalUrl = ENV.NODE_API_URL + 'api/mandals/list';
        const fullData = await axios.get(mandalUrl);
        const mandalsData = [];
        fullData.data.data.map(function(state) {
            let stateString = state.state.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase();
            if (state.districts) {
                state.districts.map(function(district) {
                    let districtString = district.district;
                    if (district.data) {
                        district.data.map(function(mandal){
                            mandalsData.push({key: mandal.key, label:  stateString + " - " + districtString + " - " + mandal.name});
                        });
                    }
                });
            }
        });
        setMandals(mandalsData);
    };

    const getCategoriesListCall = async () => {
        const mandalUrl = ENV.NODE_API_URL + 'api/menu';
        const categories = await axios.get(mandalUrl);
        const categoriesData = [];
        for (let i = 0; i < categories.data.data.length; i++) {
            var key = categories.data.data[i].type.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase();
            for(let j=0; j < categories.data.data[i].values.length; j++) {
                categoriesData.push({key: categories.data.data[i].values[j].key, label: key + " - " + categories.data.data[i].values[j].name});
            }
        }
        setCategories(categoriesData);
    };

    const getCall = async () => {
        const customerData = JSON.parse(window.localStorage.getItem('customerData'));
        let scope = '';
        if (customerData.role == 'admin') {
            scope = 'admin';
        } else {
            scope = customerData.mandal;
        }
        const URL = ENV.NODE_API_URL + 'api/news/list/all/'+scope+window.location.search;
        const { data } = await axios.get(URL);
        setData(data.data);
        const queryParams = new URLSearchParams(window.location.search);
        const currentUrl = window.location.origin + window.location.pathname;
        setPageNo(queryParams.get("page"));
        setPageSize(queryParams.get("size"));
        setPreviousUrl(currentUrl+'?page='+(queryParams.get("page") > 0 ? parseInt(queryParams.get("page")) - 1 : 0)+'&size='+queryParams.get("size") ?? 10);
        setNextUrl(currentUrl+'?page='+(queryParams.get("page") ? parseInt(queryParams.get("page")) + 1 : 1)+'&size='+queryParams.get("size") ?? 10)
    };

    const handleClick = async (user = null) => {
        if (user == null) {
            setUserDataEdit(current => {
                const {...rest} = {};
                return rest;
            });
        } else {
            setUserDataEdit(user);
        }
    };

    const handleDeleteUser = async (userId) => {
        const deleteUrl = ENV.NODE_API_URL + 'api/news/delete/'+userId;
        await axios.delete(deleteUrl).then((response) => {
            if (response.data.status == 'success') {
                window.location.reload();
            }
        }).catch((e) => {
                alert(e.response.data.error);
            });
    };

    const approveNews = async (newsId) => {
        const approveUrl = ENV.NODE_API_URL + 'api/news/approve/'+ newsId;
        await axios.get(approveUrl).then((response) => {
            if (response.data.status == 'success') {
                window.location.reload();
            }
        }).catch((e) => {
                alert(e.response.data.error);
            });
    };

    const submitFormFunction = async () => {
        const reqBody = {
            _id : inputId,
            title: inputTitle,
            description: inputDescription,
            categories: inputCategories,
            images: inputImages,
            updated_by: inputUpdatedBy,
            youtube_video: inputYoutubeVideo,
            mandal: inputMandal,
            meta_title: inputMetaTitle,
            meta_description: inputMetaDescription,
            meta_keywords: inputMetaKeywords
        };
        if(inputId !== undefined) {
            const reqURl = ENV.NODE_API_URL + 'api/news/update';
            await axios.post(reqURl, reqBody).then((response) => {
                if (response.data.status == 'success') {
                    alert("News updated");
                    window.location.reload();
                }
            }).catch((e) => {
                alert(e.response.data.error);
            });
        } else {
            delete reqBody._id;
            const reqURl = ENV.NODE_API_URL + 'api/news/create';
            await axios.post(reqURl, reqBody).then((response) => {
                if (response.data.status == 'success') {
                    alert("News created");
                    window.location.reload();
                }
            }).catch((e) => {
                alert(e.response.data.error);
            });
        }
    };

    useEffect(() => {
        checkLoginData()
        getCall()
    }, []);

    useEffect(() => {
        getMandalsCall()
    }, [data]);

    useEffect(() => {
        getCategoriesListCall()
    }, [mandals]);

    useEffect(() => {
        setInputTitle(userDataEdit.title)
        setInputDescription(userDataEdit.description)
        setInputCategories(userDataEdit.categories)
        setInputMandal(userDataEdit.mandal)
        setInputImages(userDataEdit.images)
        setInputYoutubeVideo(userDataEdit.youtube_video)
        setInputId(userDataEdit._id)
        setInputMetaTitle(userDataEdit.meta_title)
        setInputMetaDescription(userDataEdit.meta_description)
        setInputMetaKeywords(userDataEdit.meta_keywords)
    },[userDataEdit]);

    const getFiles = (files) => {
        let images = [];
        for(let i= 0; i < files.length; i++) {
            images.push(files[i].base64);
        }
        setInputImages(images);
    }

    const setCategoryValuesFun = (val) => {
        let categoryString = [];
        for (let i = 0; i < val.length; i++) {
            categoryString.push(val[i].value);
        }
        setInputCategories(categoryString);
    }

    function modalFooterContent() {
        return (<div style={{width:"100%"}}>
            <button onClick={submitFormFunction} className="btn btn-default">Save</button> 
        </div>);
    }

    function modalContent() {
        return (
            <form id='user-submit-form'>
                <div className='container'>
                    <input type="hidden" id="record_id" name="record_id" value={inputId} onChange={val => setInputId(val.target.value)} />
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Title</label>
                        <input type="text" name='title' className="form-control" id="title" value={inputTitle} onChange={val => setInputTitle(val.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">Description</label>
                        <textarea name='description' className="form-control" id="description" value={inputDescription} onChange={val => setInputDescription(val.target.value)}></textarea>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="images" className="form-label">Images</label>
                        <FileBase64 className="form-control" type="file" multiple={ true } onDone={ getFiles.bind(this) } />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="youtube_video" className="form-label">Youtube Video</label>
                        <input type="text" className="form-control" id="youtube_video" value={inputYoutubeVideo} onChange={val => setInputYoutubeVideo(val.target.value)}/>
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='categories' className='form-label'>Categories</label>
                        <select className='form-control' multiple id='categories' name='categories' value={inputCategories} onChange={val => setCategoryValuesFun(val.target.selectedOptions)}>
                            {categories.map((item) => (
                                <option value={item.key}>{item.label}</option>
                            ))}
                        </select>
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='mandal' className='form-label'>Mandal</label>
                        <select className='form-control select2-single' id='mandal' name='mandal' value={inputMandal} onChange={val => setInputMandal(val.target.value)}>
                            {mandals.map((item) => (
                                <option value={item.key}>{item.label}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="meta_title" className="form-label">Meta Title</label>
                        <input type="text" name='meta_title' className="form-control" id="meta_title" value={inputMetaTitle} onChange={val => setInputMetaTitle(val.target.value)} />
                    </div>                    
                    <div className="mb-3">
                        <label htmlFor="meta_description" className="form-label">Meta Description</label>
                        <textarea name='meta_description' className="form-control" id="meta_description" value={inputMetaDescription} onChange={val => setInputMetaDescription(val.target.value)}></textarea>
                    </div>                    
                    <div className="mb-3">
                        <label htmlFor="meta_keywords" className="form-label">Meta Keywords</label>
                        <textarea name='meta_keywords' className="form-control" id="meta_keywords" value={inputMetaKeywords} onChange={val => setInputMetaKeywords(val.target.value)}></textarea>
                    </div>
                </div>
            </form>
        );
    }

    return (
        <div className="table-container">
            <div className="row">
                <div className="col">
                    <h5 className="pb-2 mb-0">News List <FontAwesomeIcon icon={faUser} /></h5>
                </div>
                <div className="col text-right">
                    <button onClick={() => handleClick()} className="btn btn-default low-height-btn">
                    <FontAwesomeIcon icon={faPlus} data-bs-toggle="modal" data-bs-target="#fullScreenModalDefault"/>
                    </button>
                </div>
            </div>
            <p>
            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
            </p>
            <div className="d-flex text-muted">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>Title</th>
                            <th>Mandal</th>
                            <th>Is Approved</th>
                            <th>Updated By</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((user) => (
                            <tr>
                                <td><img src={user.images[0]} width="50" height="50" /></td>
                                <td>{user.title}</td>
                                <td>{user.mandal}</td>
                                <td>{user.is_approved ? 'Approved' : 'Not Approved'}</td>
                                <td>{user.updated_by}</td>
                                <td>
                                    <div className="dropdown table-action-dropdown">
                                        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButtonSM" data-bs-toggle="dropdown" aria-expanded="false"><FontAwesomeIcon icon={faEllipsisV} /></button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtonSM">
                                        {customerData.role == 'admin' && !user.is_approved && <li><a className="dropdown-item" href="#" onClick = {() => approveNews(user._id)}>Approve</a></li>}
                                            <li><a className="dropdown-item" href="#" onClick = {() => handleClick(user)} data-bs-toggle="modal" data-bs-target="#fullScreenModalDefault"><FontAwesomeIcon icon={faPencil} />&nbsp;Edit</a></li>
                                            <li><a className="dropdown-item text-danger" onClick={() => handleDeleteUser(user._id)} href="#"><FontAwesomeIcon icon={faTrash} />&nbsp;Delete</a></li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <nav className="table-bottom-center-pagination" aria-label="Page navigation example ">
                <ul className="pagination">
                    {pageNo != 0 && pageNo != null ? (<li className="page-item">
                        <a className="page-link" href={previousUrl} aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                            <span className="sr-only">Previous</span>
                        </a>
                    </li>
                    ) : '' }
                    {data.length == pageSize ? (<li className="page-item">
                        <a className="page-link" href={nextUrl} aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                            <span className="sr-only">Next</span>
                        </a>
                    </li>) : ''}
                </ul>
            </nav>
            <ModalComponent title="Full Screen Modal" footerContent={modalFooterContent()} content={modalContent()} fullScreen="true" dataBsBackdrop="static" id="fullScreenModalDefault"/>
        </div>        
    )
}
export default adminLayout(NewsList);