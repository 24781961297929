import { Component, useRef } from 'react';
import adminLayout from '../hoc/adminLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPlus, faPencil, faTrash, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import ModalComponent from '../common/ModalComponent';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import ENV from '../../config.json';
import FileBase64 from 'react-file-base64';

const CategoriesList = () => {
    const [inputName, setInputName] = useState('');
    const [inputDescription, setInputDescription] = useState('');
    const [inputKey, setInputKey] = useState('');
    const [inputUpdatedBy, setInputUpdatedBy] = useState('');
    const [inputId, setInputId] = useState('');
    const [inputType, setInputType] = useState('');
    const [inputImage, setInputImage] = useState('');
    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [previousUrl, setPreviousUrl] = useState('');
    const [nextUrl, setNextUrl] = useState('');
    const [post, setPost] = useState({});

    const [data, setData] = useState([]);
    const [userDataEdit, setUserDataEdit] = useState([]);

    const getCall = async () => {
        const URL = ENV.NODE_API_URL + 'api/get-categorys-list'+window.location.search;
        const { data } = await axios.get(URL);
        setData(data.data);
        console.log(data);
        const queryParams = new URLSearchParams(window.location.search);
        const currentUrl = window.location.origin + window.location.pathname;
        setPageNo(queryParams.get("page"));
        setPageSize(queryParams.get("size"));
        setPreviousUrl(currentUrl+'?page='+(queryParams.get("page") > 0 ? parseInt(queryParams.get("page")) - 1 : 0)+'&size='+queryParams.get("size") ?? 10);
        setNextUrl(currentUrl+'?page='+(queryParams.get("page") ? parseInt(queryParams.get("page")) + 1 : 1)+'&size='+queryParams.get("size") ?? 10)
    };

    const handleClick = async (user = null) => {
        if (user == null) {
            setUserDataEdit(current => {
                const {...rest} = {};
                return rest;
            });
        } else {
            setUserDataEdit(user);
        }
    };

    const handleDeleteUser = async (userId) => {
        const deleteUrl = ENV.NODE_API_URL + 'api/categories/delete/'+userId;
        await axios.delete(deleteUrl).then((response) => {
            if (response.data.status == 'success') {
                window.location.reload();
            }
        }).catch((e) => {
            alert(e.response.data.error);
        });
    };

    const submitFormFunction = async () => {
        const reqBody = {
            _id : inputId,
            name: inputName,
            description: inputDescription,
            key: inputKey,
            image: inputImage,
            updated_by: inputUpdatedBy,
            type: inputType
        };
        if(inputId !== undefined) {
            const reqURl = ENV.NODE_API_URL + 'api/categories/update';
            await axios.post(reqURl, reqBody).then((response) => {
                if (response.data.status == 'success') {
                    alert("Category updated");
                    window.location.reload();
                }
            }).catch((e) => {
                alert(e.response.data.error);
            });        
        } else {
            delete reqBody._id;
            const reqURl = ENV.NODE_API_URL + 'api/categories/create';
            await axios.post(reqURl, reqBody).then((response) => {
              setPost(response.data);
              if (response.data.status == 'success') {
                alert("Category created");
                window.location.reload();
            }              
            }).catch((e) => {
                alert(e.response.data.error);
            });
        }
    };

    useEffect(() => {
        getCall()
    }, []);

    useEffect(() => {
        setInputName(userDataEdit.name)
        setInputDescription(userDataEdit.description)
        setInputKey(userDataEdit.key)
        setInputType(userDataEdit.type)
        setInputImage(userDataEdit.image)
        setInputUpdatedBy(userDataEdit.updated_by)
        setInputId(userDataEdit._id)
    },[userDataEdit]);

    const getFiles = (files) => {
        setInputImage(files.base64);
    }
    

    function modalFooterContent() {
        return (<div style={{width:"100%"}}>
            <button onClick={submitFormFunction} className="btn btn-default">Save</button> 
        </div>);
    }

    function modalContent() {
        return (
            <form id='user-submit-form'>
                <div className='container'>
                    <input type="hidden" id="record_id" name="record_id" value={inputId} onChange={val => setInputId(val.target.value)} />
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input type="text" name='name' className="form-control" id="name" value={inputName} onChange={val => setInputName(val.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="key" className="form-label">Key</label>
                        <input type="text" name='key' className="form-control" id="key" value={inputKey} onChange={val => setInputKey(val.target.value)} />
                    </div>                    
                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">Description</label>
                        <textarea name='description' className="form-control" id="description" value={inputDescription} onChange={val => setInputDescription(val.target.value)}></textarea>
                    </div>
                    {/* <div className="mb-3">
                        <label htmlFor="image" className="form-label">Image</label>
                        <input type="file" name='image' className="form-control" id="image" value={inputImage} onChange={val => console.log(val)} />
                    </div> */}
                    <div className='mb-3'>
                        <label htmlFor='type' className='form-label'>Category Type</label>
                        <select className='form-control' data-live-search="true" id='type' name='type' value={inputType} onChange={val => setInputType(val.target.value)}>
                            {ENV.CATEGORY_TYPES.split(",").map((item) => (
                                <option value={item}>{item.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase()}</option>
                            ))}
                        </select>
                    </div>
                    <div className='mb-3'>
                        <label htmlFor="images" className="form-label">Image</label>
                        <FileBase64 className="form-control" type="file" multiple={ false } onDone={ getFiles.bind(this) } />
                    </div>
                </div>
            </form>
        );
    }

    return (
        <div className="table-container">
            <div className="row">
                <div className="col">
                    <h5 className="pb-2 mb-0">Categories List <FontAwesomeIcon icon={faUser} /></h5>
                </div>
                <div className="col text-right">
                    <button onClick={() => handleClick()} className="btn btn-default low-height-btn">
                    <FontAwesomeIcon icon={faPlus} data-bs-toggle="modal" data-bs-target="#fullScreenModalDefault"/>
                    </button>
                </div>
            </div>
            <p>
            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
            </p>
            <div className="d-flex text-muted">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Key</th>
                            <th>Type</th>
                            <th>Updated By</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((user) => (
                            <tr>
                                <td><img src={user.image} width="50px" height="50px" /></td>
                                <td>{user.name}</td>
                                <td>{user.key}</td>
                                <td>{user.type}</td>
                                <td>{user.updated_by}</td>
                                <td>
                                    <div className="dropdown table-action-dropdown">
                                        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButtonSM" data-bs-toggle="dropdown" aria-expanded="false"><FontAwesomeIcon icon={faEllipsisV} /></button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtonSM">
                                            <li><a className="dropdown-item" href="#" onClick = {() => handleClick(user)} data-bs-toggle="modal" data-bs-target="#fullScreenModalDefault"><FontAwesomeIcon icon={faPencil} />&nbsp;Edit</a></li>
                                            <div className="dropdown-divider"></div>
                                            <li><a className="dropdown-item text-danger" onClick={() => handleDeleteUser(user._id)} href="#"><FontAwesomeIcon icon={faTrash} />&nbsp;Delete</a></li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <nav className="table-bottom-center-pagination" aria-label="Page navigation example ">
                <ul className="pagination">
                    {pageNo != 0 && pageNo != null ? (<li className="page-item">
                        <a className="page-link" href={previousUrl} aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                            <span className="sr-only">Previous</span>
                        </a>
                    </li>
                    ) : '' }
                    {data.length == pageSize ? (<li className="page-item">
                        <a className="page-link" href={nextUrl} aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                            <span className="sr-only">Next</span>
                        </a>
                    </li>) : ''}
                </ul>
            </nav>
            <ModalComponent title="Full Screen Modal" footerContent={modalFooterContent()} content={modalContent()} fullScreen="true" dataBsBackdrop="static" id="fullScreenModalDefault"/>
        </div>        
    )
}
export default adminLayout(CategoriesList);