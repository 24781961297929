import React from 'react';
import axios from 'axios';
import { useState} from 'react';
import { useEffect } from 'react';
import ENV from '../../config.json';
import './home.css';
import frontendLayout from '../hoc/frontendLayout';
import { NavLink as Link } from 'react-bootstrap';
import banner from '../../banner.jpg';

const Home = () => {

    const [data, setData] = useState([]);
    const [news, setnews] = useState([]);
    const URL = ENV.NODE_API_URL+'api/mandals/list';
    const URL1 = ENV.NODE_API_URL+'api/homepage/news-list?size=8';

    const getCall = async () => {
        const { data } = await axios.get(URL);
        setData(data.data);
    };

    const getLatestNews = async () => {
        const { data } = await axios.get(URL1);
        setnews(data.data);
    };

    useEffect(() => {
        getCall()
        getLatestNews()
    }, []);

    return (
        <>
        <img src={banner} className='w-100 mb-5' alt="banner" />
        <div className='home-body mb-5'>
            <div className="container mb-5">
                <div className="row g-3">
                    {news.map((newsItem, index) => (
                        <div className="col-md-3" key={index}>
                            <div className="card h-100">
                                <img className="card-img-top" src={newsItem.images[0]}  alt={newsItem.title} />
                                <div className="card-body text-start">
                                    <h5 className="card-title" style={{fontWeight: '600'}}>{newsItem.title}</h5>
                                    <span class="badge bg-info">
                                        {newsItem.mandal.toUpperCase()}
                                    </span>
                                    <p className="card-text">{newsItem.description.substring(0, 100)} ...</p>
                                </div>
                                <div className='card-footer p-0 bg-primary'>
                                    <Link href={"/news-view/"+newsItem._id} className="btn btn-primary text-light p-1">View More</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <h1 className='mb-3 fs-2'>States</h1>
            <div className='container'>
                <div className="accordion" id="accordionExample">
                    {data.map((item) => (
                        <div className="accordion-item">
                            <h4 className="accordion-header" id={'heading'+item.state}>
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#'+item.state} aria-expanded="false" aria-controls={item.state}>
                                    {item.state.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase()}
                                </button>
                            </h4>
                            <div id={item.state} className="accordion-collapse collapse" aria-labelledby={'heading'+item.state} data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    {item.districts && item.districts.map((district) => (
                                        <div>
                                            {district.data.length > 0 && 
                                            <div class="accordion" id={"accordionExample" + district.key}>
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id={"headingOne" + district.key}>
                                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne" + district.key} aria-expanded="true" aria-controls={"collapseOne" + district.key}>
                                                            { district.district }
                                                        </button>
                                                    </h2>
                                                    <div id={"collapseOne" + district.key} class="accordion-collapse collapse" aria-labelledby={"headingOne" + district.key} data-bs-parent={"#accordionExample" + district.key}>
                                                        <div class="accordion-body">
                                                            <div className="row">
                                                                {district.data.map((mandal) =>(
                                                                    <div className='col-md-3'>
                                                                        <Link href={mandal.key} className='overflow-hidden'>
                                                                            <div className='card bg-light' >
                                                                                <div className='card-body text-center card1'>
                                                                                    <p className='card-text'>{mandal.name}</p>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                ))}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                     ))}
                                </div>
                            </div>
                        </div>                        
                    ))}
                </div>
            </div>
        </div>
    </>
    )
}
export default frontendLayout(Home);
