import React from 'react';
import { useParams } from 'react-router-dom';
import { NavLink as Link } from 'react-bootstrap';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import ENV from '../../config.json';
import frontendLayout from '../hoc/frontendLayout';

const MandalHome = () => {
    const { mandal, key } = useParams();
    const [data, setData] = useState([]);
    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [previousUrl, setPreviousUrl] = useState('');
    const [nextUrl, setNextUrl] = useState('');
    const URL = key === undefined ? ENV.NODE_API_URL + 'api/mandal/' + mandal+'/null' : ENV.NODE_API_URL + 'api/mandal/' + mandal+'/' + key;

    const getCall = async () => {
        const { data } = await axios.get(URL);
        setData(data.data);
        const queryParams = new URLSearchParams(window.location.search);
        const currentUrl = window.location.origin + window.location.pathname;
        setPageNo(queryParams.get("page"));
        setPageSize(20);
        setPreviousUrl(currentUrl+'?page='+(queryParams.get("page") > 0 ? parseInt(queryParams.get("page")) - 1 : 0)+'&size='+pageSize);
        setNextUrl(currentUrl+'?page='+(queryParams.get("page") ? parseInt(queryParams.get("page")) + 1 : 1)+'&size='+pageSize);
    };

    useEffect(() => {
        getCall()
    }, []);

    return (
        <div>
            {data.mandal &&
                <div className='container py-3'>
                    <h3 className='text-center mb-5'>{data.mandal.name}</h3>
                    <div className='row'>
                        <div className='col-md-9 col-lg-9 col-sm-6'>
                            <div className='mandal-image'>
                                <img style={{width: '-webkit-fill-available'}} src={data.mandal.image} />
                            </div>
                            <div className='mandal-description'>
                                <p>{data.mandal.description}</p>
                            </div>
                            <div className='row g-3 news-list mb-3'>
                                {data.news.length && data.news.map((item) => (
                                    <div className='col-md-4'>
                                        <div className="card h-100">
                                            <img className="card-img-top" src={item.images[0]}  alt={item.title} />
                                            <div className="card-body">
                                                <h5 className="card-title" style={{fontWeight: '600'}}>{item.title}</h5>
                                                <p className="card-text">{item.description.substring(0, 100)} ...</p>
                                            </div>
                                            <div className='card-footer p-0 bg-primary'>
                                                <Link href={"/news-view/"+item._id} className="btn btn-primary text-light p-1">View More</Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='pagination justify-content-center'>
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination">
                                        <li className={pageNo != 0 && pageNo != null ? "page-item" : "page-item disabled"}><a className="page-link" href={previousUrl}>Previous</a></li>
                                        <li className="page-item"><a className="page-link" href={nextUrl}>Next</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className='col-md-3 col-lg-3 col-sm-6'>
                            {data.mandal.adds.map((add) => (
                                <div style={{padding: '10px'}}>
                                    <a href='#'><img style={{width: '100%'}} src={add} /></a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
export default frontendLayout(MandalHome);
